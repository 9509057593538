import React, {useRef, useState, useLayoutEffect} from "react"
import {connect} from "react-redux";
import "./Products.css"
import {mouseEnter, mouseLeave, setBlackHeader, toggleTopMenus} from "../actions/app";
import SnapStart from "../components/SnapStart";
import {useMediaQuery} from "react-responsive";
import {MobileBreakPoint} from "../constants";
import { Helmet } from 'react-helmet';


function Products({footerHeight, bannerHeight, logoRatio, width, height}) {
  const [layoutReady, setLayoutReady] = useState(false)
  const [scrollIntoView, setScrollIntoView] = useState(true)
  const ref = useRef(null)
  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      setLayoutReady(true)
    }, 250)
    return () => {
      clearTimeout(timer)
    }
  }, [])
  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${MobileBreakPoint+1}px)`
  })
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${MobileBreakPoint}px)`
  })

  return <div
    className={"Products Content"}
    onMouseOver={() => {
      if (scrollIntoView && ref && ref.current) {
        ref.current.scrollIntoView(true, {behavior: "smooth"})
        setScrollIntoView(false)
      }
    }}
  >
    <Helmet>
      <title>mrdandy西装定制-mr.dandy高级定制 | 产品介绍</title>
    </Helmet>
    {isDesktopOrLaptop && layoutReady && <SnapStart/>}
    {isDesktopOrLaptop &&
    <div style={{width: "100%", height: height - width * logoRatio - footerHeight - bannerHeight}}/>
    }
    {isDesktopOrLaptop && layoutReady && <SnapStart/>}
    <div ref={ref}/>
    {isDesktopOrLaptop && <div
      style={{
        width: isDesktopOrLaptop ? "calc(100% - 50px)" : "100%",
        height: height - width * logoRatio - footerHeight - bannerHeight,
        // backgroundImage: "url(/products-cover.jpg)",
        backgroundImage: `url('/${width/height>1440.0/900?"products-cover.jpg":"products-cover-2.jpg"}')`,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className={"CoverImage"}
    />}
    {!isDesktopOrLaptop && <div
      style={{
        width: isDesktopOrLaptop ? "calc(100% - 50px)" : "100%",
        height: height - width * logoRatio - footerHeight - bannerHeight,
        backgroundImage: "url(/products-cover-m.jpg)",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className={"CoverImage"}
    />}
    {isDesktopOrLaptop && layoutReady && <SnapStart/>}
    <div style={{
      width: isDesktopOrLaptop ? "calc(100% - 50px)" : "100%",
      paddingBottom: 100,
    }}
         className={"TextContainer"}
    >
      <p className={"Slogan"}>
        定制种种皆可估量，唯有匠心不可预计。<br/>
        <span style={{textAlign: "center", display: "inline-block", width: "100%"}}>-</span>
        <span className={"BerlingLtStdBold"}>
        The customization is measurable, while the craftsmanship is uncountable.<br/>
        </span>
      </p>
      <h2><span className={"BerlingLtStdBold"}>FULL CANVAS</span>  全毛衬</h2>
      <p className={"Bot100"}>
        在<span className={"BerlingLtStdBold"}>MR.DANDY</span>，体验身体最完美的呼吸方式。<br/><br/>
        纯手工定制西装消耗的从来不只是物质，还有隐藏在这之后看不见的时间打磨。前后多达<span className={"BerlingLtStdBold"}>360</span>多套工序，一万多个针脚的手工缝制，工时超过<span className={"BerlingLtStdBold"}>40</span>个小时。<br/><br/>
        有人认为款式是一件西装成败的关键，而实际上，全毛衬才是手工西装的灵魂所在。毛衬工艺，避免了工业西装工艺对优质面料原始性能的破坏，最大程度的保留了西装面料的悬垂感、柔软感和自然感。<br/><br/>
        <span className={"BerlingLtStdBold EnText"}>
        Experience the perfect breath way of the body at MR. DANDY.
        </span><br/><br/>
        <span className={"BerlingLtStdBold EnText"}>
The handmade tailored suit never just consumes the material, but also consumes time. There are more than 360 processes and over 10,000 stitches made by hand with above 40 hours.
        </span><br/><br/>
        <span className={"BerlingLtStdBold EnText"}>
          People may think design is the key to a well-made suit. In fact, full canvas is the true spirit of a handmade tailored suit. Averting the destruction of original performance of high-quality fabric by industrial suit process, the full canvas process retains the draping feeling, soft feeling and natural feeling of top quality suit fabric to the greatest extent.
        </span>
      </p>
      <h2><span className={"BerlingLtStdBold"}>PERSONAL PATTERN</span>  一人一版</h2>
      <p className={"Bot100"}>
        在<span className={"BerlingLtStdBold"}>MR.DANDY</span>，您的所有细节都会得到尊重。<br/><br/>
        一直以来，单独制版过程都是高级定制的核心之一。每一位客人都由版师单独剪裁制版，这是高级定制与成衣的最大分别。您的版型纸样都会永久保存在<span className={"BerlingLtStdBold"}>MR.DANDY</span>。若没有太大变化，之后的定制服务，都可以根据这个专属版型制作。<br/><br/>
        <span className={"BerlingLtStdBold EnText"}>
        All your personal details will be respected at MR. DANDY.
        </span><br/><br/>
        <span className={"BerlingLtStdBold EnText"}>
        The personal pattern cutting process has always been one of the most important part of bespoke suit service. The master cutter makes patterns for each customer after body measurement, which is the biggest difference between bespoke and ready-to-wear suit. The paper pattern for each customer will be stored in MR. DANDY permanently and provides life-long time service.
        </span>
      </p>
      <h2><span className={"BerlingLtStdBold"}>DETAILS</span>  细节特征</h2>
      <p className={"Bot100"}>
        在<span className={"BerlingLtStdBold"}>MR.DANDY</span>，每一道线条都有生命。<br/> <br/>
        绅士西装的每一处细节，都刺绣着他们的风度。毛衬工艺赋予了西装翻领一个完美的弧线，在翻领处用真丝线花费超过两个小时锁出的米兰眼插花孔是顶级手工西装的标志之一,而米兰眼上合尾处的<span className={"BerlingLtStdBold"}>18k</span>金线手缝金点就是<span className={"BerlingLtStdBold"}>MR.DANDY</span>的独特标识。金点细节已于<span className={"BerlingLtStdBold"}>2019</span>年申请专利设计保护。<br/><br/>
        <span className={"BerlingLtStdBold"}>MR.DANDY</span>的另外一个精致细节，在于口袋的处理。船型口袋的设计贴合于身体，又让整个设计都充满了流畅的节奏。<br/><br/>
        <span className={"BerlingLtStdBold"}>MR.DANDY</span>的每一处细节都可以被定制，亦提供西装内衬图案的私人定制服务。它可以是一张客人珍爱的油画作品，也可以是一张带着记忆的珍贵照片，身着一件<span className={"BerlingLtStdBold"}>MR.DANDY</span>，无时无刻不与自己的珍爱相拥。<br/><br/>
        <span className={"BerlingLtStdBold"}>D</span>字形的插笔袋也是<span className={"BerlingLtStdBold"}>MR.DANDY</span>的标志之一，使本来严肃的西装气质中流露出一丝生动的风情。<br/><br/>
        <span className={"BerlingLtStdBold"}>MR.DANDY</span>部也会进行一些特殊的处理。大部分工业西装很难达到完全贴服肩膀和肱二头肌，而我们采用了一种特殊工艺，在肩袖缝合处做出一个与客人身体曲线一致的部分。这种设计不仅体现在日常穿着上，在悬挂时也会呈现出一个优美的弧度。将客人的身体特征融入到整体设计里，让西装不再仅仅是一件衣服，而是身体的一个部分。<br/><br/>
        <span className={"BerlingLtStdBold EnText"}>
          Every detail is alive at MR. DANDY.
        </span><br/><br/>
        <span className={"BerlingLtStdBold EnText"}>
          Every detail on a bespoke suit shows the gentleman's style. The well-made full canvas makes the lapel a perfect roll. The flower hole on suit lapel made by Milanese button hole technique is made from pure silk, which is one of the top-quality suit symbol, and the 18k gold thread hand sewing golden dot at the side of Milanese flower holes is the unique signature of MR. DANDY. The patent protection had been applied for this golden dot design in 2019.
        </span><br/><br/>
        <span className={"BerlingLtStdBold EnText"}>
      Another fine detail of MR. DANDY lies in chest pocket. The boat-shape pocket design fits the body curve for every gentleman.
        </span><br/><br/>
        <span className={"BerlingLtStdBold EnText"}>
          Every detail of MR. DANDY can be customized at MR. DANDY, even suit linings. It can be a favorite oil painting or a precious picture with memories. Wearing MR. DANDY, you're surrounded by your loved ones all the time.
        </span><br/><br/>
        <span className={"BerlingLtStdBold EnText"}>
          The D-shape pen pocket is also one of MR. DANDY symbols. It makes the serious suit spirit with a vivid amorous feeling.
        </span><br/><br/>
        <span className={"BerlingLtStdBold EnText"}>
          Some special techniques are used for the shoulder parts of MR. DANDY products. As it is difficult for most ready-to-wear suits to fully fit the shoulders, we use a technique to match the curve of the customer's body at the shoulder sleeve suture part. Performed by everyday dressing, it also shows a beautiful curve when hanging. The body details of the customer are integrated into the overall design, making the suit not only a piece of clothing but also a part of the body.
        </span>
      </p>

      <h2><span className={"BerlingLtStdBold UpperCase"}>World Finest Fabric</span>  全球顶级面料</h2>

      <p className={"Bot100"}>
        以时间为针，空间为线，在岁月变迁中编织优雅年轮。<br/><br/>
        <span className={"BerlingLtStdBold"}>MR.DANDY</span>只选择最优秀的合作，只将最美好的事物送入您的视野。我们提供来自英国、法国和意大利的几十种品牌，共计上万种面料供顾客选择。除了网罗大部分经典西装面料，亦准备了如超高支纱、<span className={"BerlingLtStdBold"}>100%Vicuña</span>、钻石粉、兰花香、古董面料、定制条纹等珍稀面料。<br/><br/>
        <span className={"BerlingLtStdBold EnText"}>
          With time as the needle and space as thread, we weave the elegant products through the years.
        </span> <br/><br/>
        <span className={"BerlingLtStdBold EnText"}>
          To bring the best to you, MR. DANDY only selects the best fabric around the world and provides all the top fabric brands from United Kingdom, France and Italy with over ten thousands different kinds of fabrics that can be chosen for the customer. In addition to seek most of classic suit fabrics, we also prepare the rare and precious fabrics (such as ultra-high super, 100%Vicuña, diamond chip, orchid fragrance, vintage and even customized stripe fabrics).
        </span>
      </p>

      <h2><span className={"BerlingLtStdBold UpperCase"}>Top Quality Accessories
</span>  全进口辅料</h2>
      <p className={"Bot100"}>
        <span className={"BerlingLtStdBold"}>MR.DANDY</span>从来不只是表面，所有在客人看不到的地方我们也做到了极致。<br/><br/>
        <span className={"BerlingLtStdBold"}>MR.DANDY</span>的全部产品细节，大至毛衬，小至每一粒纽扣每一道缝线，均选用世界顶级的材料打造。这是<span className={"BerlingLtStdBold"}>MR.DANDY</span>为所有客人献上的诚意。<br/><br/>
        <span className={"BerlingLtStdBold EnText"}>
        MR. DANDY makes every effort to do the best for all the details, even the parts can't be seen.
        </span><br/><br/>
        <span className={"BerlingLtStdBold EnText"}>
          All the product details of MR. DANDY (including canvas, every button or thread) are made from top materials in the world. This is the sincerity from MR. DANDY.
        </span>
      </p>
    </div>
  </div>
}

const mapStateToProps = (state, ownProps) => {
  return {
    pathname: state.router.location.pathname,
    width: state.app.width,
    height: state.app.height,
    footerHeight: state.app.footerHeight,
    bannerHeight: state.app.bannerHeight,
    logoRatio: state.app.logoRatio,
    openTopMenus: state.app.openTopMenus,
    blackHeader: state.app.blackHeader,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toggleTopMenus: () => dispatch(toggleTopMenus()),
    mouseEnter: () => dispatch(mouseEnter()),
    mouseLeave: () => dispatch(mouseLeave()),
    setBlackHeader: (isBlack) => dispatch(setBlackHeader(isBlack))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Products);
