import React from "react"
import {connect} from "react-redux"
import {Link} from "react-router-dom";
import "./Footer.css"
import {mouseEnter, mouseLeave, rndID} from "../actions/app";

function Footer({rndID, pathname, mouseLeave, mouseEnter}) {

  return <div className={`Footer`}>
    <div
      onClick={()=>{
        rndID()
      }}
      className={`${(pathname === "/about-brand" || pathname === "/")? "Active" : ""}`}>
      <div className={"BerlingLtStdBold"}>
        <Link onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} to={"/about-brand"}>
          ABOUT BRAND
        </Link>
      </div>
      <div className={"HYDaSongJ"}>
        <Link onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} to={"/about-brand"}>
          关于品牌
        </Link>
      </div>
    </div>
    <div className={`${pathname === "/bespoke-process" ? "Active" : ""}`}>
      <div className={"BerlingLtStdBold"}>
        <Link onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} to={"/bespoke-process"}>
          BESPOKE PROCESS
        </Link>
      </div>
      <div className={"HYDaSongJ"}>
        <Link onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} to={"/bespoke-process"}>
          定制流程
        </Link>
      </div>
    </div>
    <div className={`${pathname === "/products" ? "Active" : ""}`}>
      <div className={"BerlingLtStdBold"}>
        <Link onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} to={"/products"}>
          PRODUCTS
        </Link>
      </div>
      <div className={"HYDaSongJ"}>
        <Link onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} to={"/products"}>
          产品介绍
        </Link>
      </div>
    </div>
    <div className={`${pathname.indexOf("/collections") !== -1 ? "Active" : ""}`}>
      <div className={"BerlingLtStdBold"}>
        <Link onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} to={"/collections"}>
          COLLECTIONS
        </Link>
      </div>
      <div className={"HYDaSongJ"}>
        <Link onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} to={"/collections"}>
          样衣展示
        </Link>
      </div>
    </div>
    <div className={`${pathname === "/our-clients" ? "Active" : ""}`}>
      <div className={"BerlingLtStdBold"}>
        <Link onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} to={"/our-clients"}>
          OUR CLIENTS
        </Link>
      </div>
      <div className={"HYDaSongJ"}>
        <Link onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} to={"/our-clients"}>
          明星案例
        </Link>
      </div>
    </div>
    <div className={`${pathname === "/contact-us" ? "Active" : ""}`}>
      <div className={"BerlingLtStdBold"}>
        <Link onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} to={"/contact-us"}>
          CONTACT US
        </Link>
      </div>
      <div className={"HYDaSongJ"}>
        <Link onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} to={"/contact-us"}>
          联系我们
        </Link>
      </div>
    </div>
  </div>
}


const mapStateToProps = (state, ownProps) => {
  return {
    pathname: state.router.location.pathname,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    mouseEnter: () => dispatch(mouseEnter()),
    mouseLeave: () => dispatch(mouseLeave()),
    rndID: ()=>dispatch(rndID()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

