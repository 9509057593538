import React, {useRef, useState, useLayoutEffect, useEffect} from "react"
import {connect} from "react-redux";
import "./BespokeProcess.css"
import {mouseEnter, mouseLeave, setBlackHeader, toggleTopMenus} from "../actions/app";
import SnapStart from "../components/SnapStart";
import {useMediaQuery} from "react-responsive";
import {MobileBreakPoint} from "../constants";
import { Helmet } from 'react-helmet';


function BespokeProcess({pathname, footerHeight, bannerHeight, logoRatio, width, height}) {
  const [layoutReady, setLayoutReady] = useState(false)
  const [scrollIntoView, setScrollIntoView] = useState(true)
  const ref = useRef(null)
  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${MobileBreakPoint+1}px)`
  })
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${MobileBreakPoint}px)`
  })

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      setLayoutReady(true)
    }, 250)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  return <div
    className={"BespokeProcess Content"}
    onMouseOver={() => {
      if (isDesktopOrLaptop && scrollIntoView && ref && ref.current) {
        ref.current.scrollIntoView(true, {behavior: "smooth"})
        setScrollIntoView(false)
      }
    }}
  >
    <Helmet>
      <title>mrdandy西装定制-mr.dandy高级定制 | 定制流程</title>
    </Helmet>
    {isDesktopOrLaptop && layoutReady && <SnapStart/>}
    {isDesktopOrLaptop &&
    <div style={{width: "100%", height: height - width * logoRatio - footerHeight - bannerHeight}}/>
    }
    {isDesktopOrLaptop && layoutReady && <SnapStart/>}
    <div ref={ref}/>
    {isDesktopOrLaptop && <div
      style={{
        width: isDesktopOrLaptop ? "calc(100% - 50px)" : "100%",
        height: height - width * logoRatio - footerHeight - bannerHeight,
        // backgroundImage: "url(/bespoke-process-cover.jpg)",
        backgroundImage: `url('/${width/height>1440.0/900?"bespoke-process-cover.jpg":"bespoke-process-cover-2.jpg"}')`,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className={"CoverImage"}
    />}
    {!isDesktopOrLaptop && <div
      style={{
        width: "100%",
        height: height - width * logoRatio - footerHeight - bannerHeight,
        backgroundImage: "url(/bespoke-process-cover-m.jpg)",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className={"CoverImage"}
    />}
    {isDesktopOrLaptop && layoutReady && <SnapStart/>}
    <div style={{
      width: isDesktopOrLaptop ? "calc(100% - 50px)" : "100%",
      paddingBottom: 100,
    }}
         className={"TextContainer"}
    >
      <p className={"Slogan"}>
        以自己的喜好为笔，在<span className={"BerlingLtStdBold"}>MR. DANDY</span>，打造全世界最独一无二的艺术<br/>
        <span style={{textAlign: "center", display: "inline-block", width: "100%"}}>-</span>
        <span className={"BerlingLtStdBold"}>
Create the most unique art in the world on the canvas of MR. DANDY by pen of own preferences.
        </span>
      </p>
      <h2>
        <span className={"BerlingLtStdBold"}>DESIGN</span>
        设计
      </h2>
      <p className={"Bot100"}>
        <span className={"BerlingLtStdBold"}>MR.DANDY</span>为客人精心准备了上万种面料，并根据客人的穿着风格、习惯以及场合设计最适合的款式。<br/><br/>
        <span className={"BerlingLtStdBold EnText"}>
MR. DANDY prepares more than ten thousands of different fabrics and provides most suitable designs by customer's dress style, habit and occasion.
        </span>
      </p>

      <h2><span className={"BerlingLtStdBold"}>MEASUREMENT</span> 量体</h2>
      <p className={"Bot100"}>
        针对人体<span className={"BerlingLtStdBold"}>50</span>处关键位置收集客人数据，量体师会根据客人的体态特征，有针对性的给予客人西装款式和风格的最佳建议。<br/><br/>
        <span className={"BerlingLtStdBold EnText"}>
          Based on measurement data from 50 critical positions of the body, the master cutter provides the best advices on suit design and style according to the customer's physical characteristics.
        </span>
      </p>

      <h2><span className={"BerlingLtStdBold"}>FITTING</span> 试样</h2>
      <p className={"Bot100"}>
        <span className={"BerlingLtStdBold"}>10-15</span>天之后，做出毛样供客人试版，并进行调整。调整至完美程度后，将以此毛样制版并留存。 <br/><br/>
        <span className={"BerlingLtStdBold EnText"}>
          The rough sample is made for customer fitting in 10-15 days and it will be adjusted by their preferences. After adjusting to perfection, this pattern will be made and stored.
        </span>
      </p>
      <h2><span className={"BerlingLtStdBold"}>COMPLETION</span> 完成</h2>
      <p className={"Bot100"}>
        试样之后<span className={"BerlingLtStdBold"}>25-30</span>天，对成品进行最后的细微调试。交付成衣后，我们会提供终身的修改和维护服务。<br/><br/>
        <span className={"BerlingLtStdBold EnText"}>
          The final subtle adjustment for the finished product will be made within 25-30 days after fitting process finishes. After delivery, we will provide the life-long adjustment and maintenance service.
        </span>
      </p>
    </div>
  </div>
}

const mapStateToProps = (state, ownProps) => {
  return {
    pathname: state.router.location.pathname,
    width: state.app.width,
    height: state.app.height,
    footerHeight: state.app.footerHeight,
    bannerHeight: state.app.bannerHeight,
    logoRatio: state.app.logoRatio,
    openTopMenus: state.app.openTopMenus,
    blackHeader: state.app.blackHeader,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toggleTopMenus: () => dispatch(toggleTopMenus()),
    mouseEnter: () => dispatch(mouseEnter()),
    mouseLeave: () => dispatch(mouseLeave()),
    setBlackHeader: (isBlack) => dispatch(setBlackHeader(isBlack))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BespokeProcess);
