import React, {useRef, useState, useLayoutEffect} from "react"
import {connect} from "react-redux";
import "./ContactUs.css"
import {mouseEnter, mouseLeave, setBlackHeader, toggleTopMenus} from "../actions/app";
import SnapStart from "../components/SnapStart";
import {useMediaQuery} from "react-responsive";
import {MobileBreakPoint} from "../constants";
import {Helmet} from 'react-helmet';


function ContactUs({footerHeight, bannerHeight, logoRatio, width, height}) {
  const [layoutReady, setLayoutReady] = useState(false)
  const [scrollIntoView, setScrollIntoView] = useState(true)
  const ref = useRef(null)
  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${MobileBreakPoint + 1}px)`
  })
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${MobileBreakPoint}px)`
  })
  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      setLayoutReady(true)
    }, 250)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  return <div
    className={"ContactUs Content"}
    onMouseOver={() => {
      if (isDesktopOrLaptop && scrollIntoView && ref && ref.current) {
        ref.current.scrollIntoView(true, {behavior: "smooth"})
        setScrollIntoView(false)
      }
    }}
  >
    <Helmet>
      <title>mrdandy西装定制-mr.dandy高级定制 | 联系我们</title>
    </Helmet>
    {isDesktopOrLaptop && layoutReady && <SnapStart/>}
    {isDesktopOrLaptop &&
    <div style={{width: "100%", height: height - width * logoRatio - footerHeight - bannerHeight}}/>
    }
    {isDesktopOrLaptop && layoutReady && <SnapStart/>}
    <div ref={ref}/>
    {isDesktopOrLaptop &&
    <div
      style={{
        width: isDesktopOrLaptop ? "calc(100% - 50px)" : "100%",
        height: height - width * logoRatio - footerHeight - bannerHeight,
        backgroundImage: `url('/${width / height > 1440.0 / 900 ? "contact-us-cover.jpg" : "contact-us-cover-2.jpg"}')`,
        // backgroundImage: "url(/contact-us-cover.jpg)",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className={"CoverImage"}
    />}
    {!isDesktopOrLaptop &&
    <div
      style={{
        width: isDesktopOrLaptop ? "calc(100% - 50px)" : "100%",
        height: height - width * logoRatio - footerHeight - bannerHeight,
        backgroundImage: "url(/contact-us-cover-m.jpg)",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className={"CoverImage"}
    />}

    {isDesktopOrLaptop && <div style={{paddingBottom: 50}}/>}
    <div style={{
      width: isDesktopOrLaptop ? "calc(100% - 50px)" : "100%",
      paddingTop: isDesktopOrLaptop ? 0 : 64,
      paddingBottom: 100,
    }}>
      {isDesktopOrLaptop && layoutReady && <SnapStart/>}
      <div className={"Addresses"}>
        <div className={"LeftCol"}>
          <span className={"BerlingLtStdBold"}>
          ADDRESS <br/>
          </span>
          店铺地址
        </div>
        <div className={"RightCol"}>
          <div className={"AddressRow"}>
            <div>
              <div>
                <span className={"BerlingLtStdBold"}>
                BEIJING<br/>
                </span>
                <span>
                北京店
                </span>
              </div>
              <div>


                <span className={"BerlingLtStdBold"}>
                NO. 103, XINGYUANHUI COMMERCE-SUBJACENT,<br/>
                <span className={"BerlingLtStdBold"}>
                </span>
                 NO. 63 XINYUAN STREET, CHAOYANG DISTRICT,  <br/>
                <span className={"BerlingLtStdBold"}>
                </span>
                 BEIJING, CHINA <br/>
                </span>
                北京市朝阳区新源街63号底商103
              </div>
            </div>
            <div><img src={"/beijing.svg"}/></div>
          </div>
          <div className={"AddressRow"}>
            <div>
              <div>
                <span className={"BerlingLtStdBold"}>
                SHANGHAI<br/>
                </span>
                上海店
              </div>
              <div>
                <span className={"BerlingLtStdBold"}>
                RM 502, WALDORF DOLLAR BUILDING,<br/>
                NO.51, GUANGDONG ROAD, HUANGPU DISTRICT, <br/>
                SHANGHAI, CHINA<br/>
                </span>
                上海市黄浦区广东路51号华尔道大来大楼502
              </div>
            </div>
            <div><img src={"/shanghai.png"}/></div>
          </div>
          <div className={"AddressRow"}>
            <div>
              <div>
                <span className={"BerlingLtStdBold"}>
                SHENZHEN<br/>
                </span>
                深圳店
              </div>
              <div>
                <span className={"BerlingLtStdBold"}>
                6009, BUILDING C, NEO ENTERPRISE BUILDING, <br/>
                SHENNAN ROAD, FUTIAN DISTRICT, <br/>
                SHENZHEN, GUANGDONG, CHINA<br/>
                </span>
                广东省深圳市福田区深南大道6009号NEO大厦C座
              </div>
            </div>
            <div><img src={"/shenzhen.svg"}/></div>
          </div>
          <div className={"AddressRow"}>
            <div>
              <div>
                <span className={"BerlingLtStdBold"}>
                WUHAN<br/>
                </span>
                武汉店
              </div>
              <div>
                <span className={"BerlingLtStdBold"}>
                604 WUHAN WANDA CENTER, <br/>
                NO. 96 LINJIANG ROAD, JIYU BRIDGE STREET,  <br/>
                WUCHANG DISTRICT, WUHAN, CHINA<br/>
                </span>
                湖北省武汉市武昌区积玉桥街临江大道96号武汉万达中心604
              </div>
            </div>
            <div><img src={"/wuhan.svg"}/></div>
          </div>
          <div className={"AddressRow"}>
            <div>
              <div>
                <span className={"BerlingLtStdBold"}>
                ZHENGZHOU<br/>
                </span>
                郑州店
              </div>
              <div>
                <span className={"BerlingLtStdBold"}>
                  ROOM 201-A, FLOOR 2, KAILIN BUILDING,<br/>
                  INTERSECTION OF RUYI WEST ROAD<br/>
                  AND RUYI HEXI SECOND STREET,<br/>
                  JINSHUI DISTRICT, ZHENGZHOU,<br/>
                  HENAN PROVINCE<br/>
                </span>
                河南省郑州市金水区如意⻄路与如意河⻄二街交叉口楷林大厦2层201-A
              </div>
            </div>
            <div><img src={"/zhengzhou.svg"}/></div>
          </div>
          <div className={"AddressRow"}>
            <div>
              <div>
                <span className={"BerlingLtStdBold"}>
                HANGZHOU<br/>
                </span>
                杭州店
              </div>
              <div>
                <span className={"BerlingLtStdBold"}>
                  BUILDING 4,NO.90 CHENGXING ROAD,<br/>
                  SHANGCHENG DISTRICT,<br/>
                  HANGZHOU,ZHEJIANG <br/>
                </span>
                浙江省杭州市上城区城星路90号4幢
              </div>
            </div>
            <div><img src={"/hangzhou.svg"}/></div>
          </div>
        </div>
      </div>
      {isDesktopOrLaptop && layoutReady && <SnapStart/>}
      <div className={"ContactList"}>
        <div className={"LeftCol"}>
                <span className={"BerlingLtStdBold"}>
          CONTACT<br/>
                </span>
          联系方式
        </div>
        <div className={"RightCol"}>
          <div>
            <div className={"ContactRow"}>
              <div>
                <span className={"BerlingLtStdBold"}>
                TELEPHONE<br/>
                </span>
                全国统一客服电话
              </div>
              <div>
                <span className={"BerlingLtStdBold"}>
                400-886-5786
                </span>
              </div>
            </div>
            <div className={"ContactRow"}>
              <div>
                <span className={"BerlingLtStdBold"}>
                WECHAT ID<br/>
                </span>
                全国统一客服微信
              </div>
              <div>
                <span className={"BerlingLtStdBold"}>
              mrdandyservice
                </span>
              </div>
            </div>
            <div className={"ContactRow"}>
              <div>
                <span className={"BerlingLtStdBold"}>
                E-MAIL<br/>
                </span>
                邮箱
              </div>
              <div>
                <span className={"BerlingLtStdBold"}>
                huzhen@mrdandy.com.cn
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*{isDesktopOrLaptop && layoutReady && <SnapStart/>}*/}
      <div className={"ContactList"}>
        <div className={"LeftCol"}>
                <span className={"BerlingLtStdBold"}>
          SOCIAL MEDIA<br/>
                </span>
          官方媒体
        </div>
        <div className={"RightCol"}>
          <div>
            <div className={"ContactRow"}>
              <div>
                <span className={"BerlingLtStdBold"}>
                INSTAGRAM
                </span>
              </div>
              <div>
                <span className={"BerlingLtStdBold"}>
                mrdandybespoke
                </span>
              </div>
            </div>
            <div className={"ContactRow"}>
              <div>
                <span className={"BerlingLtStdBold"}>
                WEIBO<br/>
                </span>
                新浪微博
              </div>
              <div>
                <span className={"BerlingLtStdBold"}
                      style={{verticalAlign: "middle"}}
                >
                MrDandy
                </span>官方微博
              </div>
            </div>
            <div className={"ContactRow"}>
              <div>
                <span className={"BerlingLtStdBold"}>
                WECHAT PUBLIC<br/> ACCOUNTS<br/>
                </span>
                微信公众号
              </div>
              <div><img src={"/微信二维码.svg"}/></div>
            </div>
            <div className={"ContactRow"}>
              <div>
                <span className={"BerlingLtStdBold"}>
                TIK TOK<br/>
                </span>
                抖音
              </div>
              <div>
                <span className={"BerlingLtStdBold"}
                      style={{verticalAlign: "middle"}}
                >
                Mr.Dandy
                </span>胡榛
              </div>
            </div>
            <div className={"ContactRow"}>
              <div>
                <span className={"BerlingLtStdBold"}>
                BILIBILI<br/>
                </span>
              </div>
              <div>
                <span className={"BerlingLtStdBold"}
                      style={{verticalAlign: "middle"}}
                >
                MrDandy
                </span>胡榛
              </div>
            </div>
            <div className={"ContactRow"}>
              <div>
                <span className={"BerlingLtStdBold"}>
                XIAOHONGSHU<br/>
                </span>
                小红书
              </div>
              <div>
                <span className={"BerlingLtStdBold"}
                      style={{verticalAlign: "middle"}}
                >
                Mr. Dandy
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

const mapStateToProps = (state, ownProps) => {
  return {
    pathname: state.router.location.pathname,
    width: state.app.width,
    height: state.app.height,
    footerHeight: state.app.footerHeight,
    bannerHeight: state.app.bannerHeight,
    logoRatio: state.app.logoRatio,
    openTopMenus: state.app.openTopMenus,
    blackHeader: state.app.blackHeader,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toggleTopMenus: () => dispatch(toggleTopMenus()),
    mouseEnter: () => dispatch(mouseEnter()),
    mouseLeave: () => dispatch(mouseLeave()),
    setBlackHeader: (isBlack) => dispatch(setBlackHeader(isBlack))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
