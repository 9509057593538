import React from "react"

export default function (props) {
  const {width, height} = props
  return <svg {...props} width={width || 456} height={height || 56} viewBox="0 0 456 56" version="1.1">
    <path
      d="M60.8,3.69,65.41,0H50.48L33.31,44.45l0-1A28.49,28.49,0,0,0,31.11,31.1L17.88,0H1.9L6.51,3.69,4.88,51.87,0,55.93H14.22L9.61,52.24l1.49-44L31.38,55.93H34L52.42,8.15l1.49,44.09L49.3,55.93h18l-4.88-4.06Z"/>
    <circle cx="141.69" cy="51.19" r="4.74"/>
    <path
      d="M330.86,3.79V45.35l-1.45-3.78a28.37,28.37,0,0,0-4.69-8L296.73,0H283.46l4.74,3.79V52.14l-4.74,3.79h14.22l-4.74-3.79V10.23l38.35,45.7h4.31V3.79L340.34,0H326.12Z"/>
    <path
      d="M114.69,37.54,113,35.08a13.13,13.13,0,0,0-7.57-5c6.9-1.83,11.23-5.94,11.23-13.94C116.61,4.27,107.13,0,93.85,0h-19l4.74,3.79V52.14l-4.74,3.79h18l-4.74-3.79V4.74H92c8.53,0,15.17,2.53,15.17,10.43v1.89c0,7.9-6.64,10.43-15.17,10.43H88.17l4.74,4.74h.94c4.74,0,8,1.23,10.76,5.16h0l13.14,18.54h12.62L125,52.11Z"/>
    <path
      d="M451.31,3.75,456,0H441.25l4.62,3.7L429.6,31.82l-.11-1a28.49,28.49,0,0,0-3.71-11.24l-9.2-15.78L421.32,0H402l4.74,3.79,19,32.52V52.14l-4.74,3.79h18l-4.74-3.79V33.33Z"/>
    <polygon
      points="250.12 0 236.44 0 241.71 4.39 243.08 7.26 221.73 52.23 217.1 55.93 231.7 55.93 227 52.18 233.32 38.87 251.19 38.87 255.93 34.13 235.57 34.13 245.71 12.77 255.93 34.13 264.53 52.12 259.76 55.93 279.86 55.93 275 52.05 250.12 0"/>
    <path
      d="M373.52,0H347.93l4.74,3.79V52.14l-4.74,3.79h25.59c13.27,0,27.5-9.48,27.5-23.7V23.7h0C401,9.48,386.79,0,373.52,0Zm18,30.34c0,13.27-10.43,20.85-19,20.85H358.4l.56-1.33A28.39,28.39,0,0,0,361.2,38.8V4.74h11.37c8.54,0,19,7.58,19,20.86Z"/>
    <path
      d="M188.66,0h-25.6l4.74,3.79V52.14l-4.74,3.79h25.6c13.27,0,27.49-9.48,27.49-23.7V23.7h0C216.15,9.48,201.93,0,188.66,0Zm18,30.34c0,13.27-10.43,20.85-19,20.85H173.53l.56-1.33a28.39,28.39,0,0,0,2.24-11.06V4.74h11.38c8.53,0,19,7.58,19,20.86Z"/>
  </svg>
}
