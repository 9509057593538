export const UPDATE_APP_SIZE = "UPDATE_APP_SIZE"
export const TOGGLE_TOP_MENUS = "TOGGLE_TOP_MENUS"
export const MOUSE_ENTER = "MOUSE_ENTER"
export const MOUSE_LEAVE = "MOUSE_LEAVE"
export const SET_AUTO_SCROLLING = "SET_AUTO_SCROLLING"
export const SET_BLACK_HEADER = "SET_BLACK_HEADER"

export const SELECT_SECTION = "SELECT_SECTION"

export const CHANGE_LANG = "CHANGE_LANG"

export function selectSection(sectionId) {
  return (dispatch) => {
    dispatch({type: SELECT_SECTION, payload: sectionId})
  }
}

export const RND_ID = "RND_ID"
export function rndID(sectionId) {
  return (dispatch) => {
    dispatch({type: RND_ID})
  }
}

export function updateAppSize(width, height) {
  return (dispatch) => {
    dispatch({type: UPDATE_APP_SIZE, payload: {width, height}})
  }
}

export function toggleTopMenus() {
  return (dispatch) => {
    dispatch({type: TOGGLE_TOP_MENUS})
  }
}

export function mouseEnter() {
  return (dispatch) => {
    dispatch({type: MOUSE_ENTER})
  }
}

export function mouseLeave() {
  return (dispatch) => {
    dispatch({type: MOUSE_LEAVE})
  }
}

let autoScrollingTimer

export function setAutoScrolling() {
  return (dispatch) => {
    clearTimeout(autoScrollingTimer)
    dispatch({type: SET_AUTO_SCROLLING, payload: true})
    autoScrollingTimer = setTimeout(() => {
      dispatch({type: SET_AUTO_SCROLLING, payload: false})
    }, 2000)
  }
}

export function setBlackHeader(isBlack) {
  return (dispatch) => {
    dispatch({type: SET_BLACK_HEADER, payload: isBlack})
  }
}

export function changeLang(lang) {
  return (dispatch) => {
    dispatch({type: CHANGE_LANG, payload: lang})
  }
}