import React, {useRef, useState, useLayoutEffect, useEffect} from "react"
import {connect} from "react-redux";
import "./Home.css"
import {mouseEnter, mouseLeave, rndID, setBlackHeader, toggleTopMenus} from "../actions/app";
import SnapStart from "../components/SnapStart";
import {useMediaQuery} from 'react-responsive'
import {MobileBreakPoint} from "../constants";
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.min.css';

import SwiperCore, {Autoplay} from 'swiper';
import axios from "axios";
import {Helmet} from "react-helmet";

SwiperCore.use([Autoplay]);


function Home({randID, footerHeight, bannerHeight, logoRatio, width, height}) {
  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${MobileBreakPoint+1}px)`
  })
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${MobileBreakPoint}px)`
  })
  const [layoutReady, setLayoutReady] = useState(false)
  const [scrollIntoView, setScrollIntoView] = useState(true)
  const [slides, setSlides] = useState(null)
  const ref = useRef(null)
  const topRef = useRef(null)
  useEffect(() => {
    if (randID > 0 && topRef && topRef.current) {
      topRef.current.scrollIntoView(false, {behavior: "smooth"})
    }
  }, [randID])

  useEffect(() => {
    axios.get(`/api/v1/settings`).then(({data}) => {
      if(data.slides) {
        setSlides(data.slides.split(",").map(s=>`/images/${s}/750`))
      }
    })
  }, [])

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      setLayoutReady(true)
    }, 250)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  // slides = ["/products-cover.jpg", "/collections-cover.jpg"]

  return <div
    className={"Home Content"}
    onMouseOver={() => {
      if (isDesktopOrLaptop && scrollIntoView && ref && ref.current) {
        ref.current.scrollIntoView(true, {behavior: "smooth"})
        setScrollIntoView(false)
      }
    }}
  >
    <Helmet>
      {window.location.pathname === '/' && <title>mrdandy,mr.dandy,西装定制,高级定制</title>}
      {window.location.pathname !== '/' && <title>mrdandy,mr.dandy,西装定制,高级定制 | 关于品牌</title>}
    </Helmet>
    {isDesktopOrLaptop && layoutReady && <SnapStart/>}
    {isDesktopOrLaptop && <div ref={topRef}/>}
    {isDesktopOrLaptop && <div
      style={{
        width: "100%",
        height: height - width * logoRatio - footerHeight - bannerHeight
      }}/>
    }
    {isDesktopOrLaptop && layoutReady && <SnapStart/>}
    {isDesktopOrLaptop && <div ref={ref}/>}
    {isDesktopOrLaptop && <div
      style={{
        width: "calc(100% - 50px)",
        height: height - width * logoRatio - footerHeight - bannerHeight,
        backgroundImage: `url('/${width/height>1440.0/900?"home-cover.jpg":"home-cover-2.jpg"}')`,
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className={"CoverImage"}
    />
    }
    {(isTabletOrMobile && window.location.pathname !== '/') && <div
      style={{
        width: "100%",
        height: height - width * logoRatio - footerHeight - bannerHeight,
        backgroundImage: "url(/home-cover-m.jpg)",
        backgroundPosition: "center top",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className={"CoverImage"}
    />
    }
    {(isTabletOrMobile && window.location.pathname === '/' && !!slides) && <div
      style={{
        width: "100%",
        height: "100%",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Swiper
        direction={'vertical'}
        slidesPerView={1}
        // cssMode={true}
        loop={true}
        speed={500}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        // onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
      >
        {slides && slides.map((slideContent, index) => (
          <SwiperSlide key={slideContent} virtualIndex={index}>
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundImage: `url(${slideContent})`,
                backgroundPosition: "center top",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                display: "block",
              }}
              className={"CoverImage"}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>}
    {/*{(isTabletOrMobile && window.location.pathname !== '/') && slides.map((slideContent, index) => (*/}
    {/*  <div*/}
    {/*    key={slideContent}*/}
    {/*    style={{*/}
    {/*      width: "100%",*/}
    {/*      height: "100%",*/}
    {/*      backgroundImage: `url(${slideContent})`,*/}
    {/*      backgroundPosition: "center top",*/}
    {/*      backgroundRepeat: "no-repeat",*/}
    {/*      backgroundSize: "cover",*/}
    {/*      display: "block",*/}
    {/*      marginBottom: 20,*/}
    {/*    }}*/}
    {/*    className={"CoverImage"}*/}
    {/*  />*/}
    {/*))}*/}
    {isDesktopOrLaptop && layoutReady && <SnapStart/>}
    {(isDesktopOrLaptop || window.location.pathname !== '/') &&
    <div
      style={{
        width: isDesktopOrLaptop ? "calc(100% - 50px)" : "100%",
        paddingBottom: 100,
      }}
      className={"TextContainer"}
    >
      <p className={"Slogan"}>
        <span>绅士，从来不只是徒有其表<br/><span className={"BerlingLtStdBold"}>MR.DANDY</span>，从来不只是量体裁衣
</span><br/>
        <span style={{textAlign: "center", display: "inline-block", width: "100%"}}>-</span>
        <span className={"BerlingLtStdBold UpperCase"}>Gentlemen go beyond their good appearances</span><br/>
        <span className={"BerlingLtStdBold UpperCase"}>MR. DANDY goes beyond his tailored suits </span><br/>
      </p>
      <p className={"PLogo"}>
        <img src={"/products-logo.svg"}/>
      </p>
      {/*<h2><span className={"BerlingLtStdBold"}>FULL FUR LINING </span>全毛衬</h2>*/}
      <p className={"Bot100"}>
        <span className={"BerlingLtStdBold"}>MR.DANDY</span>高级定制创立于<span className={"BerlingLtStdBold"}>2014</span>年。品牌创立至今，已成为中国男装高级定制的标杆，客户覆盖政商、体育、演艺界名流，目前已于北京、上海、深圳、武汉、郑州、杭州设有<span
        className={"BerlingLtStdBold"}>6</span>家门店。<br/><br/>
        “喜好精致衣物的男人”是<span className={"BerlingLtStdBold"}>MR.DANDY</span>的名词解释。作为形容词，它代表了“顶级的、做工精细的” 衣物。这样的<span
        className={"BerlingLtStdBold"}>MR.DANDY</span>总是精致优雅，总是会对生活有独到的追求，也是我们渴望塑造的当代绅士。<br/><br/>
        <span className={"BerlingLtStdBold"}>MR.DANDY</span>高级定制品牌，区别于传统的高级定制，在西装的经典延续基础上融入了全新的理解与诠释。<span
        className={"BerlingLtStdBold"}>MR.DANDY</span>希望不仅能够将最好的产品带到更多男士的生活中，同时把“高级定制”的服务概念做到深入人心。除了坚持最顶级的服装品质之外，更致力于满足精致男士生活的全方位定制需求。
        <br/><br/>
        <span className={"BerlingLtStdBold EnText"}>
        Since its establishment in 2014, MR. DANDY has become in a leading position of Chinese bespoke brands, covering celebrities in politics, business, sports and entertainment industries. At present, there are 6 stores in Beijing, Shanghai, Shenzhen, Wuhan, Zhengzhou and Hangzhou all over China.
        </span><br/><br/>
        <span className={"BerlingLtStdBold EnText"}>
          The word "DANDY" itself describes "a man devoted to style, neatness and fashion in dress and appearance". As an adjective, it represents "top quality" clothes. Always being delicate and elegant, MR. DANDY has a unique pursuit of life. Besides, it is also a type of contemporary gentleman that we aspire to create.
        </span><br/><br/>
        <span className={"BerlingLtStdBold EnText"}>
          Different from traditional bespoke brand, MR. DANDY integrates new understanding and interpretation into classic menswear on the basis of continuing the traditions. MR. DANDY hopes to bring the best product to more gentlemen and makes more aesthetic customers enjoy bespoke service. In addition to adhering to the highest quality, it's more committed to meeting the all-round customization requirements of delicate men in life.
        </span>
      </p>
      <br/>
      <img src={"/home-detail.jpg"}/>
      {/*<h2><span className={"BerlingLtStdBold"}>IMPORTED AUXILIARY MATERIALS</span> 全进口辅料</h2>*/}
    </div>
    }
  </div>
}

const mapStateToProps = (state, ownProps) => {
  return {
    pathname: state.router.location.pathname,
    width: state.app.width,
    height: state.app.height,
    footerHeight: state.app.footerHeight,
    bannerHeight: state.app.bannerHeight,
    logoRatio: state.app.logoRatio,
    openTopMenus: state.app.openTopMenus,
    blackHeader: state.app.blackHeader,
    randID: state.app.randID,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toggleTopMenus: () => dispatch(toggleTopMenus()),
    mouseEnter: () => dispatch(mouseEnter()),
    mouseLeave: () => dispatch(mouseLeave()),
    setBlackHeader: (isBlack) => dispatch(setBlackHeader(isBlack))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
