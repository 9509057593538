import React from "react"
import "./CollectionItem.css"
import {useMediaQuery} from "react-responsive";
import {MobileBreakPoint} from "../constants";
import {Helmet} from "react-helmet";

function CollectionItem({collection, collectionType}) {

  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${MobileBreakPoint}px)`
  })

  return <div className={"GridWrapper CollectionItem"}>
    {(collection && collection.nameCn) && <Helmet>
      <title>MR.DANDY | {collection.nameCn}</title>
    </Helmet>
    }
    {collection && collection.images && collection.images.split(",").map((img, key) => {
      return <div className={"GridItem GridItemWithImg"} key={key}>
        <img src={`/images/${img}/900`}/>
      </div>
    })}
    <div className={"TypeName"}>
      {collectionType === "formalwear" &&
      <span> <span className={"BerlingLtStdBold Square"}>(</span><span
        className={"BerlingLtStdBold"}>FORMALWEAR</span> 礼服<span
        className={"BerlingLtStdBold Square Square2"}>)</span> </span>
      }
      {collectionType === "suits" &&
      <span> <span className={"BerlingLtStdBold Square"}>(</span><span
        className={"BerlingLtStdBold"}>SUIT</span> 西服套装<span
        className={"BerlingLtStdBold Square Square2"}>)</span> </span>
      }
      {collectionType === "blazer-jackets" &&
      <span> <span className={"BerlingLtStdBold Square"}>(</span><span
        className={"BerlingLtStdBold"}>BLAZER & JACKET</span> 单西与夹克<span
        className={"BerlingLtStdBold Square Square2"}>)</span> </span>
      }
      {collectionType === "overcoat" &&
      <span> <span className={"BerlingLtStdBold Square"}>(</span><span className={"BerlingLtStdBold"}> OVERCOAT</span> 大衣<span
        className={"BerlingLtStdBold Square Square2"}>)</span> </span>
      }
    </div>
    <div className={`GridItem GridItemForText`}>
      <div className={"GridText"}>
        {(collection && collection.nameEn) && collection.nameEn.split("\n").map((l, i) => {
          return <span key={i} className={"BerlingLtStdBold"}>{l}<br/></span>
        })}
        {(collection && collection.nameCn) && collection.nameCn.split("\n").map((l, i) => {
          return <span key={i}>{l}<br/></span>
        })}
        {isDesktopOrLaptop &&
        <span className={"BerlingLtStdBold Price"}>
                    {(collection && collection.price) ? `∙ ${collection.price.trim()} ∙` : ""}
        </span>
        }
      </div>
    </div>
    <div className={"GridItem GridItemForText"}>
      <div className={"GridText GridText2"}>
        {(collection && collection.detailsEn) && collection.detailsEn.split("\n").map((l, i) => {
          return <span key={i} className={"BerlingLtStdBold"}>{l}<br/></span>
        })}
        {(collection && collection.detailsCn) && collection.detailsCn.split("\n").map((l, i) => {
          return <span key={i}>{l}<br/></span>
        })}
        {isDesktopOrLaptop && <br/>}
        {isDesktopOrLaptop && <br/>}
      </div>
    </div>
    {!isDesktopOrLaptop && <div className={`GridItem GridItemForText`}>
      <div className={"GridText"}>
        <span className={"BerlingLtStdBold Price"}>
                    {(collection && collection.price) ? `∙ ${collection.price.trim()} ∙` : ""}
        </span>
      </div>
    </div>}
    <div className={"TipTexts"}>
          <span className={"BerlingLtStdBold"}>
          If you have any questions, you may contact us to assist you. <br/>
          </span>
      如果您有任何疑问，请联系我们。
    </div>
  </div>
}

export default CollectionItem;
