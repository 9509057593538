import React from "react"
import "./Collection.css"
import {Link} from "react-router-dom";

function Collection({mouseLeave, mouseEnter, collectionType, collections}) {
  if (!collectionType) {
    collectionType = "formalwear"
  }
  return <div className={"GridWrapper Collection"}>
    {collections && collections.map((c, key) => {
      return <div className={"GridItem"} key={key}>
        <Link
          onClick={mouseLeave}
          to={`/collections/${collectionType}/${c.id}`}
          onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
          {c.cover && <img src={`/images/${c.cover}/600`}/>}
        </Link>
        <div className={"GridText"}>
          <Link
            onClick={mouseLeave}
            to={`/collections/${collectionType}/${c.id}`}
            onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
            {c.nameEn && c.nameEn.split("\n").map((l, i) => {
              return <span key={i} className={"BerlingLtStdBold"}>{l}<br/></span>
            })}
            {c.nameCn && c.nameCn.split("\n").map((l, i) => {
              return <span key={i}>{l}<br/></span>
            })}
          </Link>
          <br/>
          <span className={"BerlingLtStdBold"}>
            {c.price ? `∙ ${c.price.trim()} ∙` : ""}
          </span>
        </div>
      </div>
    })}
  </div>
}

export default Collection;
