import React from "react"
import "./TopBanner.css"
import {mouseEnter, mouseLeave} from "../actions/app";
import {connect} from "react-redux";

function TopBanner({mouseEnter, mouseLeave}) {
  const date=new Date();
  return <div className={"TopBanner"}>
    <div>
      <span className={"BerlingLtStdBold"}>COPYRIGHT ©️ 2014-{date.getFullYear()} 北京唯臻商贸有限公司 <a href="https://beian.miit.gov.cn/" target="_blank">京ICP备16038627号</a>
        {/*MR. DANDY </span> <span className={"HYDaSongJ"}>高级定制*/}
    </span>
    </div>
  </div>
}

const mapStateToProps = (state, ownProps) => {
  return {
    pathname: state.router.location.pathname,
    openTopMenus: state.app.openTopMenus,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    mouseEnter: () => dispatch(mouseEnter()),
    mouseLeave: () => dispatch(mouseLeave()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBanner);


