import React, {useRef, useState, useLayoutEffect} from "react"
import {connect} from "react-redux";
import "./OurClients.css"
import {mouseEnter, mouseLeave, setBlackHeader, toggleTopMenus} from "../actions/app";
import SnapStart from "../components/SnapStart";

import debounce from "react-reveal/src/lib/debounce";
import Masonry from 'react-masonry-css'
import {useMediaQuery} from "react-responsive";
import {MobileBreakPoint} from "../constants";
import axios from "axios";
import {Helmet} from 'react-helmet';


function OurClients({footerHeight, bannerHeight, logoRatio, width, height}) {
  const [layoutReady, setLayoutReady] = useState(false)
  const [scrollIntoView, setScrollIntoView] = useState(true)
  const [clients, setClients] = useState(null)
  const [hasMore, setHasMore] = useState(true)
  const [viewIndex, setViewIndex] = useState(0)
  const [showLoading, setShowLoading] = useState(false)
  const [hideSnap, setHideSnap] = useState(false)
  const ref = useRef(null)
  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${MobileBreakPoint + 1}px)`
  })
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${MobileBreakPoint}px)`
  })
  const viewCount = isDesktopOrLaptop ? 8 : 8
  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      setLayoutReady(true)
    }, 250)
    axios.get(`/api/v1/clients`).then(({data}) => {
      if (data.length > 100) {
        data = data.slice(0, 100)
      }
      setClients(data)
      if (data.length < viewCount) {
        setViewIndex(data.length)
      } else {
        setViewIndex(viewCount)
      }
    })
    return () => {
      clearTimeout(timer)
    }
  }, [])

  const debounceLoad = debounce(() => {
    if (!clients) {
      return
    }
    if (viewIndex + viewCount < clients.length) {
      setViewIndex(viewCount + viewIndex)
    } else {
      setViewIndex(clients.length)
    }
    setShowLoading(false)
  }, 1000)

  const handleScroll = (e) => {
    // if (isDesktopOrLaptop) {
    //   return
    // }
    const bottom = Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight) < 20;
    // console.log(e.target.scrollHeight - e.target.scrollTop, e.target.clientHeight, e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight)
    if (bottom) {
      if(viewIndex !== clients.length) {
        setShowLoading(true)
      }
      setHideSnap(true)
      debounceLoad()
    }
  }

  return <div
    className={"OurClients Content"}
    onScroll={handleScroll}
    onMouseOver={() => {
      if (isDesktopOrLaptop && scrollIntoView && ref && ref.current) {
        // console.log("scroll into view")
        ref.current.scrollIntoView(true, {behavior: "smooth"})
        setScrollIntoView(false)
      }
    }}
  >
    <Helmet>
      <title>mrdandy西装定制-mr.dandy高级定制 | 明星案例</title>
    </Helmet>
    {(!hideSnap && isDesktopOrLaptop && layoutReady) && <SnapStart/>}
    {isDesktopOrLaptop &&
    <div style={{width: "100%", height: height - width * logoRatio - footerHeight - bannerHeight}}/>
    }
    {(!hideSnap && isDesktopOrLaptop && layoutReady) && <SnapStart/>}
    {isDesktopOrLaptop &&
    <div ref={ref}/>
    }
    {isDesktopOrLaptop &&
    <div style={{
      width: isDesktopOrLaptop ? "calc(100% - 50px)" : "100%",
      height: height - width * logoRatio - footerHeight - bannerHeight,
      backgroundImage: "url(/our-clients-cover.jpg)",
      backgroundPosition: "center top",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    }}
         className={"CoverImage"}
    />
    }
    {isDesktopOrLaptop &&
    <div style={{paddingBottom: 50}}/>
    }
    <div style={{
      width: isDesktopOrLaptop ? "calc(100% - 50px)" : "100%",
      paddingBottom: showLoading ? 0: 100,
    }}>
      <Masonry
        breakpointCols={isDesktopOrLaptop ? 2 : 1}
        className="OurClientsMasonryGrid"
        columnClassName="OurClientsMasonryGridColumn">
        {clients && clients.slice(0, isDesktopOrLaptop ? viewIndex : viewIndex).map((c, key) => {
          return <div key={key}>
            <img src={`/images/${c.cover}/750`}/>
            <div>
            <span
              className={"BerlingLtStdBold"}>{c.titleEn}<br/></span>
              {c.titleCn}
            </div>
          </div>
        })}
      </Masonry>
      {showLoading && <img className={"LoadingMore"} src={isDesktopOrLaptop?"/loading.gif":"/loading-m.gif"}/>}
    </div>
  </div>
}

const mapStateToProps = (state, ownProps) => {
  return {
    pathname: state.router.location.pathname,
    width: state.app.width,
    height: state.app.height,
    footerHeight: state.app.footerHeight,
    bannerHeight: state.app.bannerHeight,
    logoRatio: state.app.logoRatio,
    openTopMenus: state.app.openTopMenus,
    blackHeader: state.app.blackHeader,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toggleTopMenus: () => dispatch(toggleTopMenus()),
    mouseEnter: () => dispatch(mouseEnter()),
    mouseLeave: () => dispatch(mouseLeave()),
    setBlackHeader: (isBlack) => dispatch(setBlackHeader(isBlack))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OurClients);
