import React, {useState} from "react"
import {connect} from "react-redux"
import {Link} from "react-router-dom";
import "./MobileHeader.css"
import {mouseEnter, mouseLeave, rndID} from "../actions/app";
import LogoIcon from "./LogoIcon";

function MobileHeader({rndID, pathname, mouseLeave, mouseEnter}) {
  const [openMenu, setOpenMenu] = useState(false)

  return <div className={`MobileHeader ${openMenu ? "Opened" : ""}`}>
    {!openMenu && <div
      className={"HeaderBar"}
    >
      <div
        onClick={() => {
          setOpenMenu(true)
        }}
      >
        <svg
          className={"MobileOpen"}
          width="30px" height="24px" viewBox="0 0 30 24" version="1.1"
        >
          <polygon stroke="none" fill="#000000" fillRule="evenodd" points="0 2.88 30 2.88 30 0.95904 0 0.95904"/>
          <polygon stroke="none" fill="#000000" fillRule="evenodd" points="0 13.44 30 13.44 30 11.52 0 11.52"/>
          <polygon stroke="none" fill="#000000" fillRule="evenodd" points="0 24 30 24 30 22.08 0 22.08"/>
        </svg>
      </div>
      <div
        onClick={()=>{
          setOpenMenu(false)
        }}
      >
        <Link to={"/"} style={{display:"inline-block"}}>
          <LogoIcon className={"MobileLogo"}/>
        </Link>
      </div>
    </div>}
    {openMenu && <div
      className={"HeaderBar"}
    >
      <div
        onClick={() => {
          setOpenMenu(false)
        }}
      >
        <svg
          className={"MobileClose"}
          width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
          <polygon stroke="none" fill="#000000" fillRule="evenodd"
                   points="22.5874719 0 0 22.5884702 1.41252808 24 24 1.41152982"/>
          <polygon stroke="none" fill="#000000" fillRule="evenodd"
                   points="1.41252808 0 0 1.41152982 22.5874719 24 24 22.5884702"/>
        </svg>
      </div>
      <div
        onClick={()=>{
          setOpenMenu(false)
        }}
      >
        <Link to={"/"}>
          <LogoIcon className={"MobileLogo"}/>
        </Link>
      </div>
    </div>}
    <div
      className={"HeaderBody"}
      onClick={()=>{
        setOpenMenu(false)
      }}
    >
      <div
        onClick={() => {
          rndID()
        }}
        className={`${(pathname === "/about-brand" || pathname === "/") ? "Active" : ""}`}>
        <div className={"BerlingLtStdBold"}>
          <Link onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} to={"/about-brand"}>
            ABOUT BRAND
          </Link>
        </div>
        <div className={"HYDaSongJ"}>
          <Link onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} to={"/about-brand"}>
            关于品牌
          </Link>
        </div>
      </div>
      <div className={`${pathname === "/bespoke-process" ? "Active" : ""}`}>
        <div className={"BerlingLtStdBold"}>
          <Link onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} to={"/bespoke-process"}>
            BESPOKE PROCESS
          </Link>
        </div>
        <div className={"HYDaSongJ"}>
          <Link onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} to={"/bespoke-process"}>
            定制流程
          </Link>
        </div>
      </div>
      <div className={`${pathname === "/products" ? "Active" : ""}`}>
        <div className={"BerlingLtStdBold"}>
          <Link onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} to={"/products"}>
            PRODUCTS
          </Link>
        </div>
        <div className={"HYDaSongJ"}>
          <Link onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} to={"/products"}>
            产品介绍
          </Link>
        </div>
      </div>
      <div className={`${pathname.indexOf("/collections") !== -1 ? "Active" : ""}`}>
        <div className={"BerlingLtStdBold"}>
          <Link onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} to={"/collections"}>
            COLLECTIONS
          </Link>
        </div>
        <div className={"HYDaSongJ"}>
          <Link onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} to={"/collections"}>
            样衣展示
          </Link>
        </div>
      </div>
      <div className={`${pathname === "/our-clients" ? "Active" : ""}`}>
        <div className={"BerlingLtStdBold"}>
          <Link onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} to={"/our-clients"}>
            OUR CLIENTS
          </Link>
        </div>
        <div className={"HYDaSongJ"}>
          <Link onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} to={"/our-clients"}>
            明星案例
          </Link>
        </div>
      </div>
      <div className={`${pathname === "/contact-us" ? "Active" : ""}`}>
        <div className={"BerlingLtStdBold"}>
          <Link onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} to={"/contact-us"}>
            CONTACT US
          </Link>
        </div>
        <div className={"HYDaSongJ"}>
          <Link onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} to={"/contact-us"}>
            联系我们
          </Link>
        </div>
      </div>
    </div>
  </div>
}


const mapStateToProps = (state, ownProps) => {
  return {
    pathname: state.router.location.pathname,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    mouseEnter: () => dispatch(mouseEnter()),
    mouseLeave: () => dispatch(mouseLeave()),
    rndID: () => dispatch(rndID()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);

