import React, {useRef, useState, useEffect, useLayoutEffect} from "react"
import {connect} from "react-redux";
import "./Collections.css"
import {Link} from "react-router-dom";
import {mouseEnter, mouseLeave, setBlackHeader, toggleTopMenus} from "../actions/app";
import SnapStart from "../components/SnapStart";
import CollectionItem from "../components/CollectionItem";
import Collection from "../components/Collection";
import {useMediaQuery} from "react-responsive";
import {MobileBreakPoint} from "../constants";
import Arrow from "../images/商品分类下拉箭头.svg"
import axios from "axios";
import {Helmet} from "react-helmet";

function Collections({match: {params: {type: collectionType, name: collectionID}}, footerHeight, bannerHeight, logoRatio, width, height, mouseEnter, mouseLeave, pathname}) {
  const [layoutReady, setLayoutReady] = useState(false)
  const [scrollIntoView, setScrollIntoView] = useState(true)
  const [menuOpen, setMenuOpen] = useState(false)
  const [showNav, setShowNav] = useState(false)
  const [firstOpen, setFirstOpen] = useState(true)
  const [collections, setCollections] = useState(null)
  const [collection, setCollection] = useState(null)
  const ref = useRef(null)
  const topRef = useRef(null)
  const contentRef = useRef(null)
  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${MobileBreakPoint+1}px)`
  })
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${MobileBreakPoint}px)`
  })
  // if (!collectionType) {
  //   collectionType = "formalwear"
  // }
  useEffect(() => {
    if (!collectionID) {
      setCollections(null)
      axios.get(`/api/v1/collections?type=${collectionType ? collectionType : "formalwear"}`).then(({data}) => {
        console.log(data)
        setCollections(data)
      })
    } else if (!!collectionID) {
      setCollection(null)
      axios.get(`/api/v1/collections/${collectionID}`).then(({data}) => {
        setCollection(data)
      })
    }
  }, [pathname])
  useEffect(() => {
    if (firstOpen) {
      setFirstOpen(false)
      return
    }
    if (pathname === "/collections") {
      setScrollIntoView(true)
      if (isDesktopOrLaptop && topRef && topRef.current) {
        topRef.current.scrollIntoView(false, {behavior: "smooth"})
      }
      return
    }
    if (isDesktopOrLaptop && contentRef && contentRef.current) {
      contentRef.current.scrollIntoView(true, {behavior: "smooth"})
    }
  }, [pathname])
  var showTimer
  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      setLayoutReady(true)
    }, 250)
    return () => {
      clearTimeout(timer)
      clearTimeout(showTimer)
    }
  }, [])
  console.log(collectionType, collectionID)
  return <div
    className={"Collections Content"}
    onMouseOver={() => {
      if (isDesktopOrLaptop && scrollIntoView && ref && ref.current) {
        ref.current.scrollIntoView(true, {behavior: "smooth"})
        setScrollIntoView(false)
      }
    }}
  >
    <Helmet>
      <title>mrdandy西装定制-mr.dandy高级定制 | 样衣展示</title>
    </Helmet>
    {isDesktopOrLaptop && layoutReady && <SnapStart/>}
    <div ref={topRef}/>
    {isDesktopOrLaptop &&
    <div style={{width: "100%", height: height - width * logoRatio - footerHeight - bannerHeight}}/>
    }
    {isDesktopOrLaptop && layoutReady && <SnapStart/>}
    {isDesktopOrLaptop &&
    <div ref={ref}/>
    }
    {/*{(!(collectionID || collectionType) && isDesktopOrLaptop) &&*/}
    {isDesktopOrLaptop &&
    <div style={{
      width: isDesktopOrLaptop ? "calc(100% - 50px)" : "100%",
      height: height - width * logoRatio - footerHeight - bannerHeight,
      // backgroundImage: "url(/collections-cover.jpg)",
      backgroundImage: `url('/${width/height>1440.0/900?"collections-cover.jpg":"collections-cover-2.jpg"}')`,

      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    }}
         className={"CoverImage"}
    />
    }
    {isDesktopOrLaptop &&
    <div style={{paddingBottom: 50}}/>
    }
    {isDesktopOrLaptop && layoutReady && <SnapStart/>}
    <div className={"CollectionsContainer"}>
      <div className={`CollectionsMenus ${menuOpen ? "Opened" : "Closed"}`}
           style={{
             width: isDesktopOrLaptop ? "calc(100% - 24px)" : "calc(100% + 20px)",
           }}
      >
        {!isDesktopOrLaptop && <img
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            if (!menuOpen) {
              showTimer = setTimeout(() => {
                setShowNav(true)
              }, 10)
            } else {
              setShowNav(false)
            }
            setMenuOpen(!menuOpen)
          }}
          src={Arrow}
          alt={"arrow"}
          className={menuOpen ? "Opened" : ""}
        />}
        {(isDesktopOrLaptop || menuOpen || collectionType === "formalwear" || !collectionType) &&
        <div className={"NavItem"}>
          <Link
            onClick={() => {
              setMenuOpen(false)
            }}
            className={`${(collectionType === "formalwear" || !collectionType) ? 'Active' : ''} ${showNav ? 'Show' : 'Hide'}`}
            to={"/collections/formalwear"} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
          <span className={"BerlingLtStdBold"}>
          FORMALWEAR
          <br/>
          </span>
            礼服
          </Link>
        </div>
        }
        {(isDesktopOrLaptop || menuOpen || collectionType === "suit") &&
        <div className={"NavItem"}>
          <Link
            onClick={() => {
              setMenuOpen(false)
            }}
            className={`${collectionType === 'suits' ? 'Active' : ''} ${showNav ? 'Show' : 'Hide'}`}
            to={"/collections/suits"} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
          <span className={"BerlingLtStdBold"} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
          SUIT
            <br/>
          </span>
            西服套装
          </Link>
        </div>
        }
        {(isDesktopOrLaptop || menuOpen || collectionType === "blazer-jackets") &&
        <div className={"NavItem"}>
          <Link
            onClick={() => {
              setMenuOpen(false)
            }}
            className={`${collectionType === 'blazer-jackets' ? 'Active' : ''} ${showNav ? 'Show' : 'Hide'}`}
            to={"/collections/blazer-jackets"} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
          <span className={"BerlingLtStdBold"}>
          BLAZER & JACKET
            <br/>
          </span>
            单西与夹克
          </Link>
        </div>
        }
        {(isDesktopOrLaptop || menuOpen || collectionType === "overcoat") &&
        <div className={"NavItem"}>
          <Link
            onClick={() => {
              setMenuOpen(false)
            }}
            className={`${collectionType === 'overcoat' ? 'Active' : ''} ${showNav ? 'Show' : 'Hide'}`}
            to={"/collections/overcoat"} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
          <span className={"BerlingLtStdBold"}>
          OVERCOAT
            <br/>
          </span>
            大衣
          </Link>
        </div>
        }
      </div>
      {isDesktopOrLaptop && <div style={{paddingBottom: 20}}/>}
      <div
        className={"ContentView"}
        style={{
          height: isDesktopOrLaptop ? height - footerHeight - bannerHeight - 70 : "auto",
        }}
      >
        <div
          ref={contentRef}
          style={{
            width: isDesktopOrLaptop ? "calc(100% - 50px)" : "100%",
          }}
        >
          {!!collectionID && <CollectionItem
            collection={collection}
            collectionType={collectionType}
          />}
          {!collectionID && <Collection
            collections={collections}
            collectionType={collectionType}
            isDesktopOrLaptop={isDesktopOrLaptop}
            mouseEnter={mouseEnter}
            mouseLeave={mouseLeave}
          />}

        </div>
      </div>
    </div>
    {/*{layoutReady && <SnapEnd/>}*/}
  </div>
}

const mapStateToProps = (state, ownProps) => {
  return {
    pathname: state.router.location.pathname,
    width: state.app.width,
    height: state.app.height,
    footerHeight: state.app.footerHeight,
    bannerHeight: state.app.bannerHeight,
    logoRatio: state.app.logoRatio,
    openTopMenus: state.app.openTopMenus,
    blackHeader: state.app.blackHeader,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toggleTopMenus: () => dispatch(toggleTopMenus()),
    mouseEnter: () => dispatch(mouseEnter()),
    mouseLeave: () => dispatch(mouseLeave()),
    setBlackHeader: (isBlack) => dispatch(setBlackHeader(isBlack))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Collections);
