import * as actions from "../actions/app"

const local = window.localStorage.getItem("lang")

const initialState = {
  lang: ((local === "en" || local === "cn") && local) || "cn",
  footerHeight: 108,
  bannerHeight: 68,
  logoRatio: 0.09,
  width: null,
  height: null,
  openTopMenus: false,
  showBigBall: false,
  selectedSection: null,
  autoScrolling: false,
  blackHeader: false,
  randID: 0,
  sports: {
    pdfs: [
      {name: "冰球介绍", url: "/test.pdf"},
      {name: "棒球介绍", url: "/test.pdf"},
    ]
  },
  arts: {
    pdfs: [
      {name: "KAP 介绍", url: "/test.pdf"},
      {name: "KAP 院校推荐", url: "/test.pdf"},
      {name: "KAP UGC", url: "/test.pdf"},
    ]
  },
  aspire: {
    pdfs: []
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case "@@router/LOCATION_CHANGE":
      return {...state, openTopMenus: false, selectedSection: null}
    case actions.UPDATE_APP_SIZE:
      return {...state, ...action.payload}
    case actions.TOGGLE_TOP_MENUS:
      return {...state, openTopMenus: !state.openTopMenus}
    case actions.MOUSE_ENTER:
      return {...state, showBigBall: true}
    case actions.MOUSE_LEAVE:
      return {...state, showBigBall: false}
    case actions.SELECT_SECTION:
      return {...state, selectedSection: action.payload}
    case actions.SET_AUTO_SCROLLING:
      return {...state, autoScrolling: action.payload}
    case actions.SET_BLACK_HEADER:
      return {...state, blackHeader: action.payload}
    case actions.CHANGE_LANG:
      window.localStorage.setItem("lang", action.payload)
      return {...state, lang: action.payload}
    case actions.RND_ID:
      return {...state, randID: Math.random()}
    default:
      return state
  }
}