import {
  Switch,
  Route,
} from "react-router";
import {Link} from "react-router-dom";
import {connect} from "react-redux"
import React, {useEffect} from "react"
import Footer from "./components/Footer";
import TopBanner from "./components/TopBanner";
import {withSize} from 'react-sizeme'
import {mouseEnter, mouseLeave, rndID, toggleTopMenus, updateAppSize} from "./actions/app";
import Home from "./pages/Home";
import BespokeProcess from "./pages/BespokeProcess";
import Products from "./pages/Products";
import Collections from "./pages/Collections";

import './App.css';
import OurClients from "./pages/OurClients";
import ContactUs from "./pages/ContactUs";
import LogoIcon from "./components/LogoIcon";
import {useMediaQuery} from "react-responsive";
import MobileHeader from "./components/MobileHeader";
import {MobileBreakPoint} from "./constants";


function App({pathname, showBigBall, rndID, size: {width, height}, openTopMenus, updateAppSize, toggleTopMenus, mouseEnter, mouseLeave}) {
  const ref = React.useRef(null)
  const contentRef = React.useRef(null)
  useEffect(() => {
    updateAppSize(width, height)
  }, [width, height])
  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${MobileBreakPoint+1}px)`
  })
  const isTabletOrMobile = useMediaQuery({
    query: `(max-width: ${MobileBreakPoint}px)`
  })
  // const mouse = useMouse(ref, {
  //   enterDelay: 30,
  //   leaveDelay: 30,
  // })
  return (
    <div className={`App`} ref={ref}>
      {isDesktopOrLaptop && <TopBanner/>}
      {isTabletOrMobile && <MobileHeader/>}
      {isDesktopOrLaptop && <div
        className={"LogoContainer"}
        style={{height: width * 0.09}}
      >
        <Link
          to={"/"}
          onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}
          onClick={() => {
            rndID()
          }}
        >
          <LogoIcon/>
        </Link>
      </div>
      }
      <div
        ref={contentRef}
        className={`ContentContainer`}
        onClick={(e) => {
          if (openTopMenus) {
            e.stopPropagation()
            e.preventDefault()
            toggleTopMenus()
          }
        }}>
        {(!!width && !!height) &&
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route exact path="/about-brand" component={Home}/>
          <Route exact path="/bespoke-process" component={BespokeProcess}/>
          <Route exact path="/products" component={Products}/>
          <Route exact path="/collections/:type/:name" component={Collections}/>
          <Route exact path="/collections/:type" component={Collections}/>
          <Route exact path="/collections" component={Collections}/>
          <Route path="/our-clients" component={OurClients}/>
          <Route exact path="/contact-us" component={ContactUs}/>
        </Switch>
        }
      </div>
      {isDesktopOrLaptop && <Footer/>}
      {/*{isDesktopOrLaptop &&*/}
      {/*<div*/}
      {/*  className={`${showBigBall ? "Ball Big" : "Ball"}`}*/}
      {/*  style={{*/}
      {/*    top: mouse.y,*/}
      {/*    left: mouse.x,*/}
      {/*  }}*/}
      {/*/>*/}
      {/*}*/}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    pathname: state.router.location.pathname,
    openTopMenus: state.app.openTopMenus,
    showBigBall: state.app.showBigBall,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toggleTopMenus: () => dispatch(toggleTopMenus()),
    updateAppSize: (width, height) => dispatch(updateAppSize(width, height)),
    mouseEnter: () => dispatch(mouseEnter()),
    mouseLeave: () => dispatch(mouseLeave()),
    rndID: () => dispatch(rndID())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSize({monitorHeight: true})(App));
